import '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'
import '@rails/actiontext'
import 'trix'

import './channels'
import './controllers'

// Javascript imports
import './javascripts/admin/turbolinks_prepare_for_cache'
// import './javascripts/shared/map_init'
//
import hljs from 'highlight.js/lib/core'
import json from 'highlight.js/lib/languages/json'

import ApexCharts from 'apexcharts'

window.ApexCharts = ApexCharts

ActiveStorage.start()

hljs.registerLanguage('json', json)

document.addEventListener('turbo:load', (event) => {
  hljs.highlightAll()
})
